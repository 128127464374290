<template>
  <div ref="alipayWap" v-html="alipay" />
</template>

<script>
export default {
  name: 'Vue',
  components: {},
  data() {
    return {
      alipay: ''
    }
  },

  computed: {},
  mounted() {
    this.alipay = this.$route.query.htmlData
    this.$nextTick(() => {
      this.$refs.alipayWap.children[0].submit()
    })
  },

  methods: {}
}
</script>
<style lang='css' scoped>

</style>
